import { template as template_29faf9158ab14c328807abb8e8c1db96 } from "@ember/template-compiler";
const WelcomeHeader = template_29faf9158ab14c328807abb8e8c1db96(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
