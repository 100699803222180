import { template as template_d1480b9d3e4040f68fe14cb59d1ff43e } from "@ember/template-compiler";
const FKText = template_d1480b9d3e4040f68fe14cb59d1ff43e(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
