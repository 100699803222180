import { template as template_c67f685eb7aa4d22aa8458c9110f3e87 } from "@ember/template-compiler";
const FKControlMenuContainer = template_c67f685eb7aa4d22aa8458c9110f3e87(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
