import { template as template_199c8f6a947f477388d59cf63acc715b } from "@ember/template-compiler";
const FKLabel = template_199c8f6a947f477388d59cf63acc715b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
