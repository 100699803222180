import { template as template_1e41c7920aa44903bf817e39e31541e9 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_1e41c7920aa44903bf817e39e31541e9(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
